import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import SEO from '../../components/seo'
import BannerSection from '../../components/BannerSection'
import Section from '../../components/Common/Section'
import { DescriptionBlock2 } from '../../components/description-block'
import { PreviewBlock2 } from '../../components/preview-block'
import { useTranslations } from '../../hooks/use-translations'
import {
  ModuleSection2,
  ModuleSectionItem2,
} from '../../components/module-section-2'
import RequestDemo from '../../components/RequestDemo'
import ContactForm from '../../components/contact-form'
import ModalDownloadApp from '../../components/Common/ModalDownloadApp'
import {
  dashboardIosLinkSgnl,
  dashboardAndroidLinkSgnl,
  dashboardIosLinkSgnx,
  dashboardAndroidLinkSgnx,
} from '../../data'
import { GlobalContext } from '../../context/GlobalContext'
import { AppRegionEnum } from '../../types/app-region.enum'

import SgnlImageSeo from '../../assets/images/seo/sgnl/dashboard.png'
import SgnxImageSeo from '../../assets/images/seo/sgnx/dashboard.png'

const DashboardPage: React.FC = () => {
  const images = useStaticQuery(imagesQuery)
  const { t } = useTranslations()
  const { region } = React.useContext(GlobalContext)
  const [modal, setModal] = useState(false)
  const [appModal, setAppModal] = useState(false)

  return (
    <>
      <SEO
        title={t('seo_title_dash')}
        description={t('seo_description_dash')}
        image={region === AppRegionEnum.Ru ? SgnlImageSeo : SgnxImageSeo}
        keywords={
          region === AppRegionEnum.Us ? t('keywords_dashboard') : undefined
        }
      />
      <BannerSection
        imageDesktop={images.banner.childImageSharp.gatsbyImageData}
        title={`${t('Signal')} Dashboard`}
        colorShadow="dark"
      />
      <Section>
        <DescriptionBlock2
          title={t('Dashboard title')}
          description={t('Dashboard description')}
          highlightTitleWordsByIdx={[3]}
          classNameTitle="mb-4"
        />
        <PreviewBlock2
          src={images[`preview_${region}`].childImageSharp.gatsbyImageData}
          mobileImageSrc={
            images[`preview_mobile_${region}`].childImageSharp.gatsbyImageData
          }
          srcTitle={t('_dash_image_title1')}
          srcPosition="center"
        />
      </Section>
      <ModuleSection2
        title={
          region === AppRegionEnum.Ru
            ? t('Module features')
            : t('Module advantages')
        }
      >
        <ModuleSectionItem2
          title={t('_dash_subtitle')}
          description={t('_dash_lead')}
          quote={{
            author: region === AppRegionEnum.Ru ? 'author_20' : 'author_10',
            position: t('BIM-manager'),
            text: t('_dash_quote'),
          }}
          image={images[`image_${region}`].childImageSharp.gatsbyImageData}
        />
        <ModuleSectionItem2
          title={t('_dash_subtitle2')}
          description={t('_dash_lead2')}
          quote={{
            author: region === AppRegionEnum.Ru ? 'author_19' : 'author_15',
            position: t('Construction Director'),
            text: t('_dash_quote2'),
          }}
          image={images[`image2_${region}`].childImageSharp.gatsbyImageData}
          reverseElements
        />
        <ModuleSectionItem2
          title={t('_dash_subtitle3')}
          description={t('_dash_lead3')}
          quote={{
            author: region === AppRegionEnum.Ru ? 'author_21' : 'Andrew S.',
            position: t('CIO'),
            text: t('_dash_quote3'),
          }}
          image={images[`image3_${region}`].childImageSharp.gatsbyImageData}
        />
        <ModuleSectionItem2
          title={t('_dash_subtitle4')}
          description={t('_dash_lead4')}
          quote={{
            author: region === AppRegionEnum.Ru ? 'author_18' : 'author_16',
            position:
              region === AppRegionEnum.Ru
                ? t('Field Engineer')
                : t('Senior Project Engineer'),
            text: t('_dash_quote4'),
          }}
          columnRightChildren={
            <GatsbyImage
              image={images[`image4_${region}`].childImageSharp.gatsbyImageData}
              alt={t('_dash_subtitle4')}
              title={t('_dash_subtitle4')}
              style={{ maxHeight: 320 }}
            />
          }
          reverseElements
        />
        <ModuleSectionItem2
          title={t('_dash_subtitle5')}
          description={t('_dash_lead5')}
          quote={{
            author:
              region === AppRegionEnum.Ru ? 'author_21' : 'Sergey Kazantsev',
            position:
              region === AppRegionEnum.Ru ? t('CIO') : t('Project manager'),
            text: t('_dash_quote5'),
          }}
          image={images[`image5_${region}`].childImageSharp.gatsbyImageData}
        />
      </ModuleSection2>
      <RequestDemo
        title={
          region === AppRegionEnum.Ru
            ? t('Demonstration')
            : t('Request a demo_1')
        }
        imageData={{
          image: images[`demo_${region}`].childImageSharp.gatsbyImageData,
          title: `DASHBOARD ${t('Demo')}`,
        }}
        appData={{
          text: t('Download Signal Dashboard App'),
          btnText: t('Download'),
          btnColor: 'white',
          hasBtnIcon: true,
          onClick: () => setAppModal(true),
        }}
        moduleData={{
          text: t('Dashboard demo'),
          btnText: t('Request a demo_1'),
          onClick: () => setModal(true),
          btnColor: 'transparent',
        }}
      />
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a demo of Signal')}
          requestType="Demo"
        />
      )}
      {appModal && (
        <ModalDownloadApp
          title={t('Download Dashboard app')}
          appIosLink={
            region === AppRegionEnum.Ru
              ? dashboardIosLinkSgnl
              : dashboardIosLinkSgnx
          }
          appAndroidLink={
            region === AppRegionEnum.Ru
              ? dashboardAndroidLinkSgnl
              : dashboardAndroidLinkSgnx
          }
          onClose={() => setAppModal(false)}
        />
      )}
    </>
  )
}

export default DashboardPage

const imagesQuery = graphql`
  query {
    banner: file(relativePath: { eq: "dashboard/banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    preview_ru: file(relativePath: { eq: "dashboard/signal-preview.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    preview_us: file(relativePath: { eq: "dashboard/signax-preview.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    preview_mobile_ru: file(
      relativePath: { eq: "dashboard/signal-preview-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    preview_mobile_us: file(
      relativePath: { eq: "dashboard/signax-preview-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image_ru: file(relativePath: { eq: "dashboard/signal-image.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image_us: file(relativePath: { eq: "dashboard/signax-image.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image2_ru: file(relativePath: { eq: "dashboard/signal-image-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image2_us: file(relativePath: { eq: "dashboard/signax-image-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image3_ru: file(relativePath: { eq: "dashboard/signal-image-3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image3_us: file(relativePath: { eq: "dashboard/signax-image-3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image4_ru: file(relativePath: { eq: "dashboard/signal-image-4.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 536
          height: 320
          placeholder: BLURRED
        )
      }
    }
    image4_us: file(relativePath: { eq: "dashboard/signax-image-4.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image5_ru: file(relativePath: { eq: "dashboard/signal-image-5.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image5_us: file(relativePath: { eq: "dashboard/signax-image-5.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    demo_ru: file(relativePath: { eq: "dashboard/signal-demo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 614, placeholder: BLURRED)
      }
    }
    demo_us: file(relativePath: { eq: "dashboard/signax-demo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 746, placeholder: BLURRED)
      }
    }
  }
`
